import { useState } from "react";
import { useNavigate } from "react-router";

export const useFetching = (callback) => {
    const[isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    // const navigate = useNavigate()

    const fetching = async (...params) => {
        try {
            setIsLoading(true)
            await callback(...params)
        } catch (e) {
            setError(e)
            if (e.response.status === 401) {
                // navigate(`${Auth_Root}`)
            }
        } finally {
            setIsLoading(false)
        }
    }
    
    return [fetching, isLoading, error]
}