import React, { useContext, useEffect, useState } from "react"
import CodeInput from 'react-code-input';

const CodeInputVerify = ({code, setCode}) => {

    return (
        <CodeInput
            type="number"
            fields={4}
            value={code}
            onChange={(e) => setCode(e)}
        />
    )
}

export default CodeInputVerify;