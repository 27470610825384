import React, { useContext, useEffect, useState } from "react"
import img_pack from "../img/img";
import BaseLsidebar from "./components/BaseLsidebar";
import { Context } from "../App";

const FaqPage = () => {

    const {windowWidth} = useContext(Context)

    return (
       <>
        <section className="block-wls"> 
            <div className="container">
                <div className="block-wls-layout">
                    {windowWidth > 1200 &&
                        <BaseLsidebar />
                    }
                    <div className="block-wls-content">
                        <div className="wi-row">
                            <div className="col-35-jc">
                                <div className="col-20">
                                    <div className="h-28-500-c gr8">1. Ваша продукция оригинальная?</div>
                                    <div className="h-14-400-i gr8">Наш интернет-магазин EGODA работает исключительно с официальными представителями и прямыми поставщиками косметических средств, что позволяет нам гарантировать подлинность и оригинальность каждого товара в нашем ассортименте. Это подтверждается наличием сертификатов качества, а для американских брендов — также сертификатов соответствия стандартам GMP, контролируемым FDA.</div>
                                </div>
                                <div className="col-20">
                                    <div className="h-28-500-c gr8">2. Каким образом подбирается уход?</div>
                                    <div className="h-14-400-i gr8">Подбор средств для домашнего ухода осуществляется консультантом под руководством врача-дерматолога из клиники EGODA. При этом учитываются все индивидуальные особенности, которые мы выясняем в ходе переписки с пациентом и заполнения им анкеты о состоянии кожи лица.</div>
                                </div>
                            </div>
                            <div>
                                <img src={img_pack.faq_block1} alt="img" />
                            </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-500-c gr8">3. Подарок при каждом заказе</div>
                            <div className="h-14-400-i gr8">Мы обожаем радовать наших клиентов подарками! Приобретая косметику в нашем интернет-магазине, вы получаете тестеры новинок и подходящих для вашего типа кожи средств от ведущих мировых брендов.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-500-c gr8">4. Можно ли сочетать продукты разных косметических брендов?</div>
                            <div className="h-14-400-i gr8">Если у вас есть рекомендации от вашего лечащего врача или вы регулярно пользуетесь косметикой определенной марки, вы можете приобрести средства ухода БЕЗ консультации. В остальных случаях настоятельно рекомендуем обратиться к специалистам EGODA, чтобы избежать возможных реакций кожи, таких как покраснение или шелушение. Обратите внимание, что консультация врача-дерматолога необходима при покупке продукции брендов ZO Skin Health by Zein Obagi и Ultraceuticals, так как их состав включает ретинол и кислоты. Мы всегда рады помочь вам: консультация по подбору ухода абсолютно бесплатна.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-500-c gr8">5. Можно ли приобретать косметические средства самостоятельно, без консультации?</div>
                            <div className="h-14-400-i gr8">Каждый космецевтический бренд предлагает универсальные средства для комплексного ухода, что устраняет необходимость комбинировать продукты от разных производителей. Однако, если вы желаете использовать продукцию нескольких брендов одновременно, это можно сделать после бесплатной консультации с врачом-дерматологом EGODA.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-500-c gr8">6. Могу ли я приобрести подарочный сертификат?</div>
                            <div className="h-14-400-i gr8">Безусловно. Минимальный номинал подарочного сертификата составляет 5 000 рублей. Срок его действия — 6 месяцев.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-500-c gr8">7. Как происходит получение товара?</div>
                            <div className="h-14-400-i gr8">Получить товар можно 2-мя способами: самовывозом по адресу нашей клиники 'подробный адрес клиники'; оформить доставку и в таком случае наш менеджер свяжется с вами, обсудит удобное время и адрес доставки. В назначенный час наш курьер привезет ваш заказ. </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-500-c gr8">8. Как найти товар</div>
                            <div className="h-14-400-i gr8">Чтобы найти нужный товар, откройте Каталог и выберите интересующую вас категорию. Вы также можете искать товар по названию бренда, выбрав пункт «Бренды» в меню и выбрав нужный бренд из списка. Для более точного поиска воспользуйтесь дополнительными фильтрами, которые находятся слева на странице: бренд, цена, признаки, объем.<br/><br/>Кроме того, вы можете использовать поисковую форму, вводя ключевое слово или фразу. Результаты будут отображены в виде списка товаров, отсортированных по наиболее точному совпадению с вашим запросом. Нажав на кнопку «сортировать», вы можете упорядочить товары по разным параметрам, таким как цена, популярность, алфавит и другие.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-500-c gr8">9. Как оформить заказ?</div>
                            <div className="h-14-400-i gr8">Выберите товары из каталога и добавьте их в корзину, нажав кнопку «В корзину» рядом с нужным товаром. После того как все необходимые товары будут выбраны, откройте корзину, кликнув на ее значок в верхнем правом углу любой страницы интернет-магазина, и нажмите «Оформить заказ». Далее следуйте инструкциям системы. В корзине будет отображаться информация о вашем заказе: количество выбранных товаров, их список, подарки за покупку (если имеются) и общая стоимость. Вы можете продолжить оформление заказа, изменить количество товаров, удалить отдельные товары или очистить всю корзину. После успешного оформления заказа, в личном кабинете отобразится подтвержденный заказ.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-500-c gr8">10. В течении какого времени я могу оформить заказ товаров, находящихся у меня в корзине?</div>
                            <div className="h-14-400-i gr8">Корзина сбрасывается через 14 дней. В этот период вы можете оформить заказ, находящийся в корзине. Учтите, что товар в корзине не резервируется.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-500-c gr8">11. Сколько я могу заказать товара в одном заказе?</div>
                            <div className="h-14-400-i gr8">Количество товаров в заказе не ограничено, однако можно приобрести не более 3 единиц одного товара за один раз. Общая сумма одного заказа не должна превышать 100 000 рублей.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-500-c gr8">12. Что делать если я хочу сделать заказа на сумму более 100 000 рублей?</div>
                            <div className="h-14-400-i gr8">Если вы планируете приобрести товары на сумму, превышающую 100 000 рублей, пожалуйста, оформите несколько заказов.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-500-c gr8">13. Как узнать, что мой заказ принят в работу?</div>
                            <div className="h-14-400-i gr8">После завершения оформления, заказ сразу принимается в работу. С этого момента информация о вашем заказе будет доступна в личном кабинете (если вы авторизованы на сайте). На ваш email придет письмо с подтверждением, включающее состав заказа, подарки, итоговую стоимость и сроки доставки. Чек об оплате отправляется также на email.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-500-c gr8">14. Как узнать, что происходит с заказом?</div>
                            <div className="h-14-400-i gr8">Статус Вашего заказа в любое время Вы можете посмотреть в Вашем личном кабинете (для авторизованных пользователей). Если у вас нет аккаунта, то на ваш email после оформления заказа будет отправлена информация о заказе. При смене статуса заказа будет отправлено дополнительное уведомление на указанный email. В email будет указан номер менеджера, которому можно позвонить в рабочее время и узнать информацию более подробно. </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-500-c gr8">15. Как добавить товар в заказ или заменить товар на другой?</div>
                            <div className="h-14-400-i gr8">К сожалению, изменить уже оформленный заказ невозможно. Однако, вы можете отменить текущий заказ и оформить новый.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-500-c gr8">16. Можно ли выкупить заказ частично?</div>
                            <div className="h-14-400-i gr8">К сожалению, нет. Заказ возможно получить только в полном объеме или отказаться от него полностью.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-500-c gr8">17. Какие подарки мне положат в заказ?</div>
                            <div className="h-14-400-i gr8">Подарки, которые будут вложены в ваш заказ отображаются в корзине, на момент оформления заказа. Итоговый состав заказа, включая подарки будет вам отправлен на почту, после оформления заказа.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-500-c gr8">18. Как отказаться от заказа?</div>
                            <div className="h-14-400-i gr8">Отказаться от заказа можно в личном кабинете пользователя. Если заказ оформлялся без входа в аккаунт, необходимо написать на почту email@email.ru с почты, с которой делался заказ и попросить сделать отмену заказа. Либо позвонить по телефону +7 999 999 99 99. </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-500-c gr8">19. Как узнать срок годности товара?</div>
                            <div className="h-14-400-i gr8">Со сроком годности и местом производства товара можно ознакомиться на его упаковке при получении заказа. Если у Вас возникнут дополнительные вопросы после получения заказа, Вы можете написать нам на почту email@email.ru.</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       </>
    )
}

export default FaqPage;