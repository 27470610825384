import React, { useContext, useEffect, useState } from "react"
import { Link, Outlet, useLocation } from "react-router-dom";
import AdmSide_link from "./components/Cutom_admSide_link";
import img_pack from "../img/img";
import { AdminArticlesRoot, AdminGeneralRoot, AdminMediafilesRoot, AdminStoreRoot } from "../utils/consts";

const AdminLayout = () => {

    const location = useLocation()

    return (
        <>
            <div className="admin_page">
                <div className="admin_header">
                    <div className="admin_header_row">
                        <div className="admin_logo">
                            <img src={img_pack.egoda_logo} alt="egoda" />
                            <div className="h-20-500-sf">Jane S.</div>
                        </div>
                        <div className="admin_header_icons">
                            <button>
                                <img src={img_pack.notif_icon} alt="notif_icon" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="admin_main">
                    <div className="admin_sidebar">
                        <div className="admin_sidebar_col h-14-600-sf">
                            <AdmSide_link seRoot={AdminGeneralRoot} mainTitle={'Общая сводка'} mainIcon={<img_pack.admSide_general_icon/>}></AdmSide_link>
                            <AdmSide_link mainRoot={AdminStoreRoot} mainTitle={'Магазин'} mainIcon={<img_pack.admSide_shop_icon/>} >
                                {/* <Link to={Admin_articles_Root} className={`${(location.pathname === Admin_articles_Root || location.pathname === Admin_articles_new_Root || location.pathname.includes(Admin_articles_edit_Root_base)) ? 'active' : ''}`}>Все статьи</Link> */}
                                <Link to={'#!'} className={''}>Категории</Link>
                                <Link to={'#!'} className={''}>Серии</Link>
                            </AdmSide_link>
                            <AdmSide_link mainRoot={AdminArticlesRoot} mainTitle={'Статьи'} mainIcon={<img_pack.admSide_articles_icon/>} >
                                {/* <Link to={Admin_articles_Root} className={`${(location.pathname === Admin_articles_Root || location.pathname === Admin_articles_new_Root || location.pathname.includes(Admin_articles_edit_Root_base)) ? 'active' : ''}`}>Все статьи</Link> */}
                                <Link to={'#!'} className={''}>Категории</Link>
                                <Link to={'#!'} className={''}>Серии</Link>
                            </AdmSide_link>
                            <AdmSide_link mainRoot={AdminMediafilesRoot} mainTitle={'Медиафайлы'} mainIcon={<img_pack.admSide_media_icon/>}></AdmSide_link>
                            <AdmSide_link mainRoot={'#!'} mainTitle={'Пользователи'} mainIcon={<img_pack.admSide_users_icon/>}></AdmSide_link>
                            <AdmSide_link mainRoot={'#!'} mainTitle={'Настройки'} mainIcon={<img_pack.admSide_settings_icon/>}></AdmSide_link>
                            <AdmSide_link mainRoot={'#!'} mainTitle={'Помощь'} mainIcon={<img_pack.admSide_help_icon/>}></AdmSide_link>
                        </div>
                        {/* <div className="admin_sidebar_col h-14-600-sf">
                            <span className="h-14-800-i c-1 txtc">AEGIS CORE</span>
                            <span className="h-12-400-i c-6 txtc">Версия: 1.0.0</span>
                        </div> */}
                    </div>
                    <div className="admin_content">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminLayout;