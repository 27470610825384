import product_example1 from '../img/products-block/product-example1.png'
import product_example2 from '../img/products-block/product-example2.png'
import blog_ex1 from '../img/blog-ex.jpg'

export const url = 'http://localhost:8000'
export const Page404_Root = '/404'

export const FaqPageRoot = '/Faq'
export const PaymentAndDeliveryRoot = '/Payment&delivery'
export const ExchangeAndRefundRoot = '/Exchange&refund'
export const UserAgreementRoot = '/UserAgreement'
export const PublicOfferRoot = '/PublicOffer'

export const Login_Root = '/Login'
export const Registration_Root = '/Registration'
export const Password_recovery_Root = '/PasswordRecovery'
export const Password_new_Root = '/PasswordNew'
export const Email_verify_Root = '/emailVerify'

export const AdminGeneralRoot = '/admin'
export const AdminStoreRoot = '/admin/store'
export const AdminArticlesRoot = '/admin/articles'
export const AdminMediafilesRoot = '/admin/mediafiles'
export const AdminMediafilesRedRoot = '/admin/mediafiles/red/:id'
export const AdminMediafilesRedRootBase = '/admin/mediafiles/red/'

export const egoda_url = 'https://serv9999.webbbox.ru'
// export const egoda_url = 'http://localhost:9999'

export var products = [
    {
        id: 0,
        img: product_example1,
        title: 'FAITH Пудра концентрат с витамином С ',
        category: 'Для лица',
        price: 26400,
    },
    {
        id: 1,
        img: product_example2,
        title: 'FAITH Пудра концентрат с витамином С ',
        category: 'Для лица',
        price: 25400,
    },
    {
        id: 1,
        img: product_example2,
        title: 'FAITH Пудра концентрат с витамином С ',
        category: 'Для лица',
        price: 25400,
    },
    {
        id: 1,
        img: product_example2,
        title: 'FAITH Пудра концентрат с витамином С ',
        category: 'Для лица',
        price: 25400,
    },
    {
        id: 1,
        img: product_example2,
        title: 'FAITH Пудра концентрат с витамином С ',
        category: 'Для лица',
        price: 25400,
    },
]

export var cats = [
    {
        id: 0,
        title: 'Категория 1',
        desc: 'Описание категории',
    },
    {
        id: 2,
        title: 'Категория 2',
        desc: 'Описание категории',
    },
    {
        id: 3,
        title: 'Категория 3',
        desc: 'Описание категории',
    },
    {
        id: 4,
        title: 'Категория 4',
        desc: 'Описание категории',
    },
]

export var posts = [
    {
        id: 0,
        img: blog_ex1,
        title: 'Заголовок статьи. Заголовок может быть в две строки',
        category: 'Для лица',
    },
    {
        id: 1,
        img: blog_ex1,
        title: 'Заголовок статьи. Заголовок может быть в две строки',
        category: 'Для лица',
    },
    {
        id: 2,
        img: blog_ex1,
        title: 'Заголовок статьи. Заголовок может быть в две строки',
        category: 'Для лица',
    },
    {
        id: 3,
        img: blog_ex1,
        title: 'Заголовок статьи. Заголовок может быть в две строки',
        category: 'Для лица',
    },
    {
        id: 4,
        img: blog_ex1,
        title: 'Заголовок статьи. Заголовок может быть в две строки',
        category: 'Для лица',
    },
]

export function splitPathname(pathname) {
    // Удаляем первый слэш, если он есть
    if (pathname.startsWith('/')) {
        pathname = pathname.slice(1);
    }
    // Разделяем строку по слэшам
    let parts = pathname.split('/');
    // Возвращаем массив, содержащий части пути
    return parts;
}


