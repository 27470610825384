import axios from "axios"
import { egoda_url, url } from "../utils/consts";

export default class GetAuth {
    // токен
    static async GetToken(email, password) {
        const response = await axios.post(`${egoda_url}/auth/token/`, {
            email: email,
            password: password,
        }, {
            withCredentials: true
        });

        return response;
    }
    // регистрация
    static async SignUp(username, password, email) {
        const response = await axios.post(`${egoda_url}/auth/sign_up/`, {
            username: username,
            password: password,
            email: email,
        }, {
            withCredentials: true
        });

        return response;
    }
    // Проверить код (Регистрация)
    static async InputCode(username, code) {
        const response = await axios.post(`${egoda_url}/auth/input_code/`, {
            username: username,
            code: code,
        }, {
            withCredentials: true
        });

        return response;
    }
    // Отправить код для смены пароля
    static async RecoveryVerificationCode(email) {
        const response = await axios.post(`${egoda_url}/auth/recovery_verification_code/`, {
            email: email,
        }, {
            withCredentials: true
        });
        
        return response;
    }
    // Проверить код (Изменение пароля)
    static async RecoveryInputCode(email, code) {
        const response = await axios.post(`${egoda_url}/auth/recovery_input_code/`, {
            code: code,
            email: email,
        }, {
            withCredentials: true
        });

        return response;
    }
    // Изменить пароль пользователя
    static async RecoveryPassword(username, code, password) {
        const response = await axios.post(`${egoda_url}/auth/recovery_password/`, {
            username: username,
            code: code,
            password: password,
        }, {
            withCredentials: true
        });

        return response;
    }
    
}