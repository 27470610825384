import React, { useContext, useEffect, useState } from "react"
import { Context } from "../../App";
import img_pack from "../../img/img";

const ProductExample = ({res}) => {

    const {windowWidth} = useContext(Context)

    return (
        <div className="product-example">
            <div className="product-example-img"><img src={res.img} alt="product_img" /></div>  
            <div className="product-example-content">
                <div className="h-14-400-i gr8 txtc">{res.title}</div>
                <div className="row-20-js">
                    <div className="col">
                        <div className="h-12-400-i gr5">{res.category}</div>
                        <div className="h-14-700-i">{res.price} ₽</div>
                    </div>
                    <div><button className="cart-btn h-14-400-i">{windowWidth <= 650 ? <img src={img_pack.buy_icon} alt="buy_icon" /> : 'В корзину'}</button></div>
                </div>
            </div>  
        </div>
    )
}

export default ProductExample;