import React, { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router"
import { Link } from "react-router-dom"

const AdmSide_link = ({mainRoot,seRoot, mainTitle,mainIcon, children}) => {

    const location = useLocation()
    const [mainLinkIsHovered, setMainLinkIsHovered] = useState(false)
    const [ActiveLocation, setActiveLocation] = useState(false)
    const [dpOpen, setDpOpen] = useState(false);

    useEffect(() => {
        setActiveLocation(location.pathname.includes(mainRoot) || location.pathname === seRoot)
    },[location.pathname])

    useEffect(() => {
        // const button = hoveroutRef.current.querySelector('.hoverout-dpd > button')
        // const buttonHeight = button?.scrollHeight;
        // const curContent = hoveroutRef.current.querySelector('.hoverout-dpd-content')
        // const curContentHeight = curContent?.scrollHeight;
        if(mainLinkIsHovered) {
            setDpOpen(true)
            // curContent.classList.add('open')
            // curContent.style.maxHeight = curContentHeight + 'px';
            // curContent.style.top = `${buttonHeight}px`;
        } else {
            setDpOpen(false)
            // curContent.classList.remove('open')
            // curContent.style.maxHeight = '0';
        }
    },[mainLinkIsHovered])

    return (
        <div className={`admSide-link ${ActiveLocation ? 'active' : ''}`}
            onMouseEnter={() => setMainLinkIsHovered(true)}
            onMouseLeave={() => setMainLinkIsHovered(false)}
        >
            <Link className={ (ActiveLocation) ? 'active' : ''} to={mainRoot || seRoot}>
                <div className={`admSide_svg ${(ActiveLocation || mainLinkIsHovered) ? 'active' : ''}`}>
                    {mainIcon}
                </div>
                {mainTitle}
            </Link>
            {(ActiveLocation) ?
            children &&
            <div className="se-nav-list">
                {children}
            </div>
            :
            (children && dpOpen) &&
            <div className="se-nav-list-out">
                {children}
            </div>

            }
        </div>
    )
}

export default AdmSide_link;