import React, { useContext, useEffect, useState } from "react"
import img_pack from "../img/img";
import BaseLsidebar from "./components/BaseLsidebar";
import { Context } from "../App";

const ExchangeAndRefundPage = () => {

    const {windowWidth} = useContext(Context)

    return (
       <>
        <section className="block-wls"> 
            <div className="container">
                <div className="block-wls-layout">
                    {windowWidth > 1200 &&
                        <BaseLsidebar />
                    }
                    <div className="block-wls-content">
                        <div className="col-20">
                            <div className="h-28-500-c gr8">Условия возврата</div>
                            <div className="h-14-400-i gr8">Мы стремимся обеспечить вам максимально комфортные условия для покупок и предлагаем прозрачные правила возврата и обмена товаров.</div>
                            <div className="h-20-500-c gr8">Срок возврата:</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Вы можете вернуть или обменять товар в течение 14 дней с момента получения заказа.</li>
                            </ul>
                            <div className="h-20-500-c gr8">Товары, подлежащие возврату:</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Возврату подлежат товары надлежащего качества, которые не были в употреблении и сохранили свои потребительские свойства, товарный вид, оригинальную упаковку, а также документы, подтверждающие факт и условия покупки данного товара.</li>
                            </ul>
                            <div className="h-20-500-c gr8">Процедура возврата:</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Свяжитесь с нашей службой поддержки по телефону +7 (123) 456-78-90 или по электронной почте support@example.com для оформления возврата.</li>
                                <li>Упакуйте товар в оригинальную упаковку и приложите чек.</li>
                                <li>Отправьте товар по адресу: г. Челябинск, *адрес*.</li>
                            </ul>
                            <div className="h-20-500-c gr8">Возврат денежных средств:</div>
                            <div className="h-14-400-i gr8">Возврат средств осуществляется в течение 7 рабочих дней после получения и проверки товара. Способ возврата зависит от метода оплаты:</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Банковская карта: средства будут возвращены на ту же карту, с которой была произведена оплата.</li>
                                <li>Электронные кошельки: средства будут возвращены на тот же электронный кошелек.</li>
                                <li>Наличные: возврат наличных осуществляется путем банковского перевода на указанный вами счет.</li>
                            </ul>
                        </div>
                        <div className="col-20">
                            <div className="h-28-500-c gr8">Условия обмена</div>
                            <div className="h-20-500-c gr8">Срок обмена:</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Вы можете обменять товар на аналогичный в течение 14 дней с момента получения заказа.</li>
                            </ul>
                            <div className="h-20-500-c gr8">Процедура обмена:</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Свяжитесь с нашей службой поддержки по телефону +7 (123) 456-78-90 или по электронной почте support@example.com для оформления обмена.</li>
                                <li>Упакуйте товар в оригинальную упаковку и приложите чек.</li>
                                <li>Отправьте товар по адресу: г. Москва, ул. Примерная, д. 1, индекс 123456.</li>
                                <li>После получения товара и его проверки мы отправим вам новый товар. Стоимость пересылки нового товара оплачивается покупателем, если обмен не связан с браком или ошибкой с нашей стороны.</li>
                            </ul>
                        </div>
                        <div className="col-20">
                            <div className="h-28-500-c gr8">Исключения</div>
                            <div className="h-14-400-i gr8">Обмен и возврат не распространяются на следующие категории товаров:</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Товары, изготовленные на заказ или с индивидуальными характеристиками.</li>
                                <li>Средства личной гигиены, косметика и парфюмерия.</li>
                            </ul>
                        </div>
                        <div className="col-20">
                            <div className="h-28-500-c gr8">Контакты</div>
                            <div className="h-14-400-i gr8">Если у вас есть вопросы или вам нужна помощь с оформлением возврата или обмена, пожалуйста, свяжитесь с нашей службой поддержки:</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Телефон: +7 (123) 456-78-90</li>
                                <li>Электронная почта: support@example.com</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </section>
       </>
    )
}

export default ExchangeAndRefundPage;