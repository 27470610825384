import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom";
import img_pack from "../../img/img";

const CustomDpd = ({title, children, className}) => {

    const [dpOpen, setDpOpen] = useState(false);

    function click(e) {
        setDpOpen(!dpOpen)
        const curContent = e.currentTarget.parentElement.querySelector('.dpd-content')
        const curContentHeight = curContent?.scrollHeight;

        if(curContent.classList.contains('open')) {
            curContent.classList.remove('open')
            curContent.style.maxHeight = '0';
        } else {
            curContent.classList.add('open')
            curContent.style.maxHeight = curContentHeight + 'px';
        }

    }

    return (
        <div className={`custom-dpd`}>
            <button onClick={click}>
                <span className={className}>{title}</span>
                <img src={img_pack.dpd_icon} alt="dpd_icon" style={dpOpen ? {transform: 'rotate(180deg)'} : {}} />
            </button>
            <div className={`dpd-content ${dpOpen ? 'open' : ''}`}>
                {children}
            </div>
        </div>
    )
}

export default CustomDpd;