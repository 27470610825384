import React, { useContext, useEffect, useState } from "react"

const AdminStorePage = () => {

    return (
        <div className="admin_content_base">
        </div>
    )
}

export default AdminStorePage;