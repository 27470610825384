import React, { createContext, useEffect, useState } from "react"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import Layout from "./Pages/Layout"
import Main from "./Pages/Main"
import { adminRoutes, otherRoutes, publicRoutes } from "./utils/routes"
import AdminLayout from "./Pages/AdminLayout"
import AdminGeneralPage from "./Pages/AdminGeneralPage"
import ItemPo from "./Pages/components/Item_po"
import Popup from "./Pages/components/Popup"

export const Context = createContext(null)

const App = () => {

  const location = useLocation()
  const prevLocation = location.state?.prevLocation

  // resize

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
    setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Убираем слушатель события при размонтировании компонента
    return () => {
    window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Context.Provider
      value={{windowWidth}}
    >
      <div>
        <Routes location={prevLocation || location}>

          <Route path="admin" element={<AdminLayout />}>
            <Route index element={<AdminGeneralPage />}/>
            {adminRoutes.map(({path, element}) => 
              <Route key={path} path={path} element={element}/>
            )}
          </Route>

          <Route path="/" element={<Layout />}>
            <Route index element={<Main />}/>
            {publicRoutes.map(({path, element}) => 
              <Route key={path} path={path} element={element}/>
            )}
            {otherRoutes.map(({path, element}) => 
              <Route key={path} path={path} element={element}/>
            )}
            {/* <Route path="*" element={ <Navigate to={`${Page404_Root}`} />}/>  */}
          </Route>

        </Routes>
        {prevLocation && (
        <Routes>
          <Route path="item_po" element={<Popup><ItemPo /></Popup>} />
        </Routes>
        )}
      </div>
    </Context.Provider>
  )
}

export default App;
