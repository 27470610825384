import React, { useContext, useEffect, useState } from "react"
import CustomDpd from "./Custom_dp_link";
import { Link } from "react-router-dom";

const BaseLsidebar = () => {

    return (
       <>
        <div className="base-lsidebar">
            <div className="base-lsidebar-col">

                <div className="h-16-700-i gr7">Категории товаров</div>
                <div className="col-10">
                    <CustomDpd className={'h-14-600-i gr6'} title={'Для лица'}>
                        <Link to={'#!'}>1</Link>
                        <Link to={'#!'}>2</Link>
                    </CustomDpd>
                    <CustomDpd className={'h-14-600-i gr6'} title={'Для тела'}>
                        <Link to={'#!'}>1</Link>
                        <Link to={'#!'}>2</Link>
                    </CustomDpd>
                    <CustomDpd className={'h-14-600-i gr6'} title={'Категория 3'}>
                        <Link to={'#!'}>1</Link>
                        <Link to={'#!'}>2</Link>
                    </CustomDpd>
                </div>
                <span style={{borderBottom: '2px solid #D0D5DD'}} />
                <div className="h-16-700-i gr7">Интересные статьи</div>


            </div>
        </div>
       </>
    )
}

export default BaseLsidebar;