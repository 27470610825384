import React, { useContext, useEffect, useState } from "react"
import img_pack from "../img/img";
import BaseLsidebar from "./components/BaseLsidebar";
import { Context } from "../App";

const PublicOfferPage = () => {

    const {windowWidth} = useContext(Context)

    return (
       <>
        <section className="block-wls"> 
            <div className="container">
                <div className="block-wls-layout">
                    {windowWidth > 1200 &&
                        <BaseLsidebar />
                    }
                    <div className="block-wls-content">

                        <div className="col-20">
                            <div className="h-20-500-c gr8">1. Общие положения</div>
                            <div className="h-14-400-i gr8">1.1. Настоящий документ является официальным предложением (публичной офертой) интернет-магазина [Название интернет-магазина] (далее – Продавец) любому физическому или юридическому лицу (далее – Покупатель) заключить договор купли-продажи товаров дистанционным способом на условиях, предусмотренных данной офертой.</div>
                            <div className="h-14-400-i gr8">1.2. Настоящая оферта является публичной офертой в соответствии со статьей 437 Гражданского кодекса Российской Федерации.</div>
                            <div className="h-14-400-i gr8">1.3. Акцепт (принятие) настоящей оферты осуществляется путем оформления Покупателем заказа на сайте Продавца и подтверждения оплаты. С момента акцепта оферта считается заключенной между Продавцом и Покупателем договором.</div>
                        </div>

                        <div className="col-20">
                            <div className="h-20-500-c gr8">2. Термины и определения</div>
                            <div className="h-14-400-i gr8">2.1. В настоящей оферте используются следующие термины:</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Продавец – интернет-магазин [Название интернет-магазина], осуществляющий продажу товаров дистанционным способом.</li>
                                <li>Покупатель – физическое или юридическое лицо, заключившее с Продавцом договор купли-продажи на условиях настоящей оферты.</li>
                                <li>Товар – объекты купли-продажи, представленные на сайте Продавца.</li>
                                <li>Заказ – должным образом оформленный запрос Покупателя на приобретение Товара.</li>
                            </ul>
                        </div>

                        <div className="col-20">
                            <div className="h-20-500-c gr8">3. Предмет оферты</div>
                            <div className="h-14-400-i gr8">3.1. Продавец обязуется передать в собственность Покупателя Товар, а Покупатель обязуется оплатить и принять Товар на условиях настоящей оферты.</div>
                            <div className="h-14-400-i gr8">3.2. Описание, характеристики и стоимость Товаров указаны на сайте Продавца.</div>
                        </div>

                        <div className="col-20">
                            <div className="h-20-500-c gr8">4. Порядок оформления заказа</div>
                            <div className="h-14-400-i gr8">4.1. Покупатель оформляет Заказ самостоятельно на сайте Продавца, заполняя необходимые данные и подтверждая выбор Товара.</div>
                            <div className="h-14-400-i gr8">4.2. После оформления Заказа Продавец подтверждает его по электронной почте или телефону, указанным Покупателем.</div>
                        </div>

                        <div className="col-20">
                            <div className="h-20-500-c gr8">5. Цена и оплата Товара</div>
                            <div className="h-14-400-i gr8">5.1. Цена Товара указана на сайте Продавца и может быть изменена Продавцом в одностороннем порядке до момента оформления Заказа.</div>
                            <div className="h-14-400-i gr8">5.2. Оплата Товара осуществляется Покупателем способами, указанными на сайте Продавца в разделе "Доставка и оплата".</div>
                        </div>

                        <div className="col-20">
                            <div className="h-20-500-c gr8">6. Доставка Товара</div>
                            <div className="h-14-400-i gr8">6.1. Продавец обязуется передать Товар Покупателю в срок и по адресу, указанным при оформлении Заказа.</div>
                            <div className="h-14-400-i gr8">6.2. Стоимость и условия доставки указаны на сайте Продавца в разделе "Доставка и оплата".</div>
                        </div>

                        <div className="col-20">
                            <div className="h-20-500-c gr8">7. Права и обязанности сторон</div>
                            <div className="h-14-400-i gr8">7.1. Продавец обязуется:</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Передать Покупателю Товар в соответствии с условиями настоящей оферты.</li>
                                <li>Предоставить Покупателю достоверную информацию о Товаре.</li>
                            </ul>
                            <div className="h-14-400-i gr8">7.2. Покупатель обязуется:</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Своевременно оплатить и принять Товар.</li>
                                <li>Предоставить достоверную информацию при оформлении Заказа.</li>
                            </ul>
                        </div>

                        <div className="col-20">
                            <div className="h-20-500-c gr8">8. Возврат и обмен Товара</div>
                            <div className="h-14-400-i gr8">8.1. Условия возврата и обмена Товара указаны на сайте Продавца в разделе "Обмен и возврат".</div>
                        </div>

                        <div className="col-20">
                            <div className="h-20-500-c gr8">9. Ответственность сторон</div>
                            <div className="h-14-400-i gr8">9.1. Продавец не несет ответственности за убытки Покупателя, вызванные неправильным использованием Товара.</div>
                            <div className="h-14-400-i gr8">9.2. Продавец не несет ответственности за задержку доставки Товара, вызванную обстоятельствами, находящимися вне его контроля.</div>
                        </div>

                        <div className="col-20">
                            <div className="h-20-500-c gr8">10. Прочие условия</div>
                            <div className="h-14-400-i gr8">10.1. Настоящая оферта вступает в силу с момента ее акцепта Покупателем и действует до полного исполнения обязательств сторонами.</div>
                            <div className="h-14-400-i gr8">10.2. Все споры, возникающие из настоящей оферты, подлежат урегулированию путем переговоров. В случае невозможности достижения соглашения спор подлежит рассмотрению в суде по месту нахождения Продавца.</div>
                        </div>

                        <div className="col-20">
                            <div className="h-20-500-c gr8">11. Контактная информация</div>
                            <div className="h-14-400-i gr8">11.1. Продавец:</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>[Название интернет-магазина]</li>
                                <li>Адрес: [Адрес магазина]</li>
                                <li>Телефон: +7 (123) 456-78-90</li>
                                <li>Электронная почта: support@example.com</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </section>
       </>
    )
}

export default PublicOfferPage;