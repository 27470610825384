import AdminArticlesPage from "../Pages/AdminArticlesPage";
import AdminGeneralPage from "../Pages/AdminGeneralPage";
import AdminMediafilesPage from "../Pages/AdminMediafilesPage";
import AdminMediafilesRedPage from "../Pages/AdminMediafilesRedPage";
import AdminStorePage from "../Pages/AdminStorePage";
import Auth from "../Pages/Auth";
import ExchangeAndRefundPage from "../Pages/ExchangeAndRefundPage";
import FaqPage from "../Pages/FaqPage";
import PaymentAndDeliveryPage from "../Pages/PaymentAndDeliveryPage";
import PublicOfferPage from "../Pages/PublicOfferPage";
import UserAgreementPage from "../Pages/UserAgreementPage";
import { AdminArticlesRoot, AdminGeneralRoot, AdminMediafilesRedRoot, AdminMediafilesRoot, AdminStoreRoot, Auth_Root, Email_verify_Root, ExchangeAndRefundRoot, FaqPageRoot, Login_Root, Password_new_Root, Password_recovery_Root, PaymentAndDeliveryRoot, PublicOfferRoot, Registration_Root, UserAgreementRoot } from "./consts";

export const publicRoutes = [
    {
        path: Login_Root,
        element: <Auth />,
    },
    {
        path: Registration_Root,
        element: <Auth />,
    },
    {
        path: Password_recovery_Root,
        element: <Auth />,
    },
    {
        path: Password_new_Root,
        element: <Auth />,
    },
    {
        path: Email_verify_Root,
        element: <Auth />,
    },

    // {
    //     path: Page404_Root,
    //     element: <Page404 />
    // },
    // {
    //     path: Admin_Root,
    //     element: <Admin_page />
    // },
]

export const adminRoutes = [
    {
        path: AdminGeneralRoot,
        element: <AdminGeneralPage />,
    },
    {
        path: AdminStoreRoot,
        element: <AdminStorePage />,
    },
    {
        path: AdminArticlesRoot,
        element: <AdminArticlesPage />,
    },

    {
        path: AdminMediafilesRoot,
        element: <AdminMediafilesPage />,
    },
    {
        path: AdminMediafilesRedRoot,
        element: <AdminMediafilesRedPage />,
    },
]

export const otherRoutes = [
    {
        path: FaqPageRoot,
        element: <FaqPage />,
    },
    {
        path: PaymentAndDeliveryRoot,
        element: <PaymentAndDeliveryPage />,
    },
    {
        path: ExchangeAndRefundRoot,
        element: <ExchangeAndRefundPage />,
    },
    {
        path: UserAgreementRoot,
        element: <UserAgreementPage />,
    },
    {
        path: PublicOfferRoot,
        element: <PublicOfferPage />,
    },
]