import React, { useContext } from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { Context } from '../../App';

const YandexMap = () => {

  const {windowWidth} = useContext(Context)

  return (
    <YMaps>
      <div style={{width: '100%', height: '100%'}}>
        <Map
          defaultState={{ center: [55.75, 37.57], zoom: 9 }}
          className='Ymap_content'
          style={ windowWidth > 1275 ? 
            {  maxWidth: '100%', height: '100%' } : 
            windowWidth > 700 ? { maxWidth: '700px', height: '400px' } :  
            windowWidth > 550 ? { maxWidth: '500px', height: '300px' } : 
            windowWidth > 450 ? { maxWidth: '400px', height: '250px' } :
            { maxWidth: '300px', height: '200px' }}
        >
          <Placemark geometry={[55.162649, 61.290444]} 
            options={{
            iconColor: '#FFA500', // оранжевый цвет
            preset: 'islands#darkOrangeDotIcon' // стиль метки с припиской
            }}
            properties={{
            iconCaption: 'Академика Королёва, д. 33, 10' // приписка к метке
            }}
          />
        </Map>
      </div>
    </YMaps>
  );
};

export default YandexMap;

