import React, { useContext, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import Pagination from "./components/Pagination";
import img_pack from "../img/img";
import { products } from "../utils/consts";

const AdminArticlesPage = () => {

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(null)
    const [totalPosts, setTotalPosts] = useState(null)

    // const {resPo, setResPo} = useContext(Context)

    const [postsAll, setPostsAll] = useState([])
    const navigate = useNavigate()
    const location = useLocation()
    
    function checkClick(e) {
        if (e.target.classList.contains('active')) {
            e.target.classList.remove('active')
        } else {
            e.target.classList.add('active')
        }
    }
    
    // const [GetPostsListAll, isLoading] = useFetching( async (page, tag_slug, category_slug) => {
    //     const res = await GetBlog.GetPostsListAll(page, tag_slug, category_slug)
    //     setPostsAll(res.data?.posts)
    //     setTotalPages(res.data?.total_pages)
    //     setTotalPosts(res.data?.total_posts)
    //     console.log('GetPostsListAll complete:' , res.data)
    // })
    
    useEffect(() => {
        // GetPostsListAll(currentPage,'','')
    },[currentPage])

    // const [DeletePost] = useFetching( async (id) => {
    //     const res = await GetBlog.DeletePost(id)
    //     console.log('delete complete:' , res.data)
    //     GetPostsListAll(currentPage,'','')
    // })

    // useEffect(() => {
    //     if (resPo.status === true && resPo.data.item === 'articlesDel') {
    //         DeletePost(resPo.data.id)
    //         setResPo('')
    //     }
    // }, [resPo])

    // function deletePost(id) {
    //     const data = {
    //         id: id,
    //         item: 'articlesDel',
    //         title: 'Статья будет удалена',
    //         desc: 'Вы уверены, что хотите удалить статью безвозвратно? '
    //     }
    //     navigate('/item_po', { state: { prevLocation: location, po_data: data } })
    // }

    useEffect(() => {
        console.log('currentPage', currentPage)
    },[currentPage])

    return (
        <div className="admin_content_base">
            <div className="admin_content_header">
                <div className="row-10">
                    <div className="h-18-500-i c-">Статьи</div>
                    <span className="h-12-500-i c-4 ba">{totalPosts} шт</span>
                </div>
                <Link to={'#!'} className="create_plus_btn">
                    <img src={img_pack.plus_icon} alt="plus_icon" />
                    <span className="h-14-600-i c-7">Создать</span>
                </Link>
            </div>

            <table className="articles_admin_table">
                <thead>
                    <tr>
                        <th>
                            <div>
                                <span className="check_box_base" onClick={checkClick}/>
                                Название
                            </div>
                        </th>
                        <th>
                            <div>
                                Статус
                                <img src={img_pack.dpd_icon} alt="dpd_icon" />
                            </div>
                        </th>
                        <th>
                            <div>Дата публикации</div>
                        </th>
                        <th>
                            <div>Категории</div>
                        </th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {products?.map((el) => (
                        <tr key={el.id}>
                            <td className="t-name">
                                <div>
                                    <span className="check_box_base" onClick={checkClick}/>
                                    <img src={img_pack.egoda_logo} alt="admin_logo_example" />
                                    <span className="h-14-500-i c-7">{el.title}</span>
                                </div>
                            </td>
                            <td className="t-status">
                                {el.status === 'PB' ?
                                <div className="ba-pub">
                                    <div />
                                    <span className="h-12-500-i">Опубликовано</span>
                                </div>
                                : 
                                <div className="ba-dr">
                                    <div />
                                    <span className="h-12-500-i">Черновик</span>
                                </div>
                                }
                            </td>
                            <td className="t-date">
                                <div>
                                    <span className="h-14-400-i c-6">{format(new Date(), "dd.MM.yyyy")}</span>
                                </div>
                            </td>
                            <td className="t-cats">
                                <div>
                                    {el.categories?.map((el) => (
                                        <div key={el.id} className="h-12-500-i c-4 ba">{el.name}</div>
                                    ))}

                                    {/* <button className="h-12-500-i c-3 ba_more_btn">+4</button> */}
                                </div>
                            </td>
                            <td className="t-icons">
                                <div>
                                    <button><img src={img_pack.trash_icon} alt="trash_icon" /></button>
                                    <Link to={`#!`}><img src={img_pack.edit_icon} alt="edit_icon" /></Link>
                                </div>
                            </td>

                        </tr>
                    ))
                    }
                </tbody>
            </table>
            {totalPages > 1 &&
            <div className="t_foot">
                <button onClick={() => setCurrentPage( ((currentPage - 1) >= 1) ? (currentPage - 1) : (currentPage) )}>
                    <span className="prev_arrow_svg"/>
                    <span className="h-14-600-i c-8">Предыдущее</span>
                </button>
                <Pagination totalPages={totalPages} pagesToShow={1} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                <button onClick={() => setCurrentPage( ((currentPage + 1) <= totalPages) ? (currentPage + 1) : (currentPage) )}>
                    <span className="h-14-600-i c-8">Следующие</span>
                    <span className="next_arrow_svg"/>
                </button>
            </div>
            }
        </div>
    )
}

export default AdminArticlesPage;