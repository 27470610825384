import React, { useContext, useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import img_pack from "../img/img";
import GetMediafiles from "../API/GetMediafiles";
import { useFetching } from "../hooks/UseFetching";

const AdminMediafilesRedPage = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const [fileData, setFileData] = useState([]);
    const {id} = useParams()
    const [itemPodata, setItemPodata] = useState({title: 'Файл будет удален', desc: 'Вы уверены, что хотите удалить файл безвозвратно?'});

    let confirmPo = location.state?.confirmPo

    const [GetFile,load, err] = useFetching( async (pk) => {
        const res = await GetMediafiles.GetFile(pk)
        console.log('GetFile complete:' , res.data)
        setFileData(res.data.data)
    })
    
    useEffect(() => {
        err && console.log(err)
    },[err])
    
    useEffect(() => {
        GetFile(id)
    },[])

    useEffect(() => {
        if (confirmPo) {
            // Обнуление состояния confirm в истории
            confirmPo = undefined
            window.history.replaceState({}, '')
            // Логика на confirmPo
            console.log('Удаление')
        }
    }, [confirmPo])

    return (
        <div className="admin_content_base">
            <div className="admin_content_header">
                <div className="h-18-500-i gr8">Информация о файле</div>
                <div className="row-10-a">
                    <Link to={'/item_po'} state={{prevLocation: location, itemPodata: itemPodata}} className="admin_delete_btn">
                        <img src={img_pack.trash_icon} alt="trash_icon" />
                        <span className="h-14-500-i white">Удалить</span>
                    </Link>
                    <Link to={'#!'} className="admin_save_btn">
                        <img src={img_pack.save_icon} alt="save_icon" />
                        <span className="h-14-500-i white">Сохранить</span>
                    </Link>
                </div>
            </div>

            <div className="mediafiles-content-red">
                <div><img src={img_pack.media_ex} alt="img-ex" /></div>
                <div>
                    <div>
                        <div><span className="h-12-500-i">Дата загрузки:</span> <span className="h-12-400-i">{fileData?.created}</span></div>
                        <div><span className="h-12-500-i">Кто загрузил:</span> <span className="h-12-400-i">{fileData?.creator}</span></div>
                        <div><span className="h-12-500-i">Имя файла:</span> <span className="h-12-400-i">{fileData?.file_name}</span></div>
                        <div><span className="h-12-500-i">Категория файла:</span> <span className="h-12-400-i">{fileData?.category}</span></div>
                        <div><span className="h-12-500-i">Тип файла:</span> <span className="h-12-400-i">png</span></div>
                        <div><span className="h-12-500-i">Размер файла:</span> <span className="h-12-400-i">4 МБ</span></div>
                        <div><span className="h-12-500-i">Разрешение:</span> <span className="h-12-400-i">1280 x 853 px</span></div>
                    </div>
                    <span style={{borderBottom: '1px solid #D0D5DD'}}/>
                    <form className="mediafiles-red-form">
                        <div className="col-5">
                            <span className="h-14-500-i gr7">Атрибут alt</span>
                            <input name="alt" className="h-16-400-i gr5 base-input" placeholder="Напишите атрибут alt..." type="text" />
                        </div>
                        <div className="col-5">
                            <span className="h-14-500-i gr7">Заголовок</span>
                            <input name="alt" className="h-16-400-i gr5 base-input" placeholder="Тестовый файл" type="text" />
                        </div>
                        <div className="col-5">
                            <span className="h-14-500-i gr7">Подпись</span>
                            <input name="alt" className="h-16-400-i gr5 base-input" placeholder="Напишите подпись..." type="text" />
                        </div>
                        <div className="col-5">
                            <span className="h-14-500-i gr7">Описание</span>
                            <input name="alt" className="h-16-400-i gr5 base-input" placeholder="Напишите описание..." type="text" />
                        </div>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default AdminMediafilesRedPage;