import React, { useContext, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom";
import img_pack from "../../img/img";

const HoveroutDpd = ({title, children, className}) => {

    const [dpOpen, setDpOpen] = useState(false);
    const [IsHovered, setIsHovered] = useState(false)

    const hoveroutRef = useRef(null)

    useEffect(() => {
        const button = hoveroutRef.current.querySelector('.hoverout-dpd > button')
        const buttonHeight = button?.scrollHeight;
        const curContent = hoveroutRef.current.querySelector('.hoverout-dpd-content')
        const curContentHeight = curContent?.scrollHeight;
        if(IsHovered) {
            setDpOpen(true)
            curContent.classList.add('open')
            curContent.style.maxHeight = curContentHeight + 'px';
            curContent.style.top = `${buttonHeight}px`;
        } else {
            setDpOpen(false)
            curContent.classList.remove('open')
            curContent.style.maxHeight = '0';
        }
    },[IsHovered])

    return (
        <div className={`hoverout-dpd`}
            ref={hoveroutRef}                 
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <button>
                <span className={className}>{title}</span>
                <img src={img_pack.dpd_icon} alt="dpd_icon" style={dpOpen ? {transform: 'rotate(180deg)'} : {}} />
            </button>
            <div className={`hoverout-dpd-content ${dpOpen ? 'open' : ''}`}>
                {children}
            </div>
        </div>
    )
}

export default HoveroutDpd;