import React, { useContext, useEffect, useState } from "react"
import img_pack from "../../img/img";
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Context } from "../../App";
import CustomDpd from "./Custom_dp_link";
import { Email_verify_Root, FaqPageRoot, Login_Root, Password_new_Root, Password_recovery_Root, Registration_Root, splitPathname } from "../../utils/consts";
import HoveroutDpd from "./HoveroutDpd";

const Header = () => {

    const {windowWidth} = useContext(Context)
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [navArr, setNavArr] = useState([]);

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if(location) {
           setNavArr(splitPathname(location.pathname))
        }
    },[location])

    return (
    <>
        {windowWidth <= 1200 &&
        <div className={`overlay ${menuIsOpen ? 'open' : ''}`} onClick={() => setMenuIsOpen(false)}>
            <div className={`mobile_menu ${menuIsOpen ? 'open' : ''}`} onClick={e => e.stopPropagation()}>
                <div className="mobile_menu_content">
                    <div>
                        <div className="col">
                            <div className="mobile_menu_row_js">
                                <Link to={'#'} className="egoda-row-txt-mobile-menu">
                                    <img src={img_pack.egoda_logo} alt="logo" />
                                    <img src={img_pack.egoda_txt} alt="egoda-txt" />
                                </Link>
                                <button className="close-btn" onClick={() => setMenuIsOpen(false)}><img src={img_pack.close_icon} alt="close_icon" /></button>
                            </div>
                            <Link to={'#!'} className="txtc">+ 7 (999) 999-99-99</Link>
                        </div>
                        <div className="col">
                            <CustomDpd title={'Магазин'}>
                                <Link to={'#!'}>1</Link>
                                <Link to={'#!'}>2</Link>
                                <Link to={'#!'}>3</Link>
                            </CustomDpd>
                            <CustomDpd title={'Категории товаров'}>
                                <Link to={'#!'}>1</Link>
                                <Link to={'#!'}>2</Link>
                                <Link to={'#!'}>3</Link>
                            </CustomDpd>
                            <CustomDpd title={'Бренды'}>
                                <Link to={'#!'}>1</Link>
                                <Link to={'#!'}>2</Link>
                                <Link to={'#!'}>3</Link>
                            </CustomDpd>
                            <Link className="menu-link" to={'#!'}>Акции</Link>
                            <Link className="menu-link" to={'#!'}>Наборы</Link>
                            <Link className="menu-link" to={'#!'}>Скидки</Link>
                        </div>
                    </div>
                    <div></div>

                </div>
            </div>
        </div>
        }
    <div className="container">
       <header>
            {windowWidth > 1200 &&
            <div className="header-main-row pre-header">
                <div className="row-15-a">
                    {/* <div className="row-5-a">
                        <img src={img_pack.toggle_icon_example} alt="toggle" />
                        <div className="h-12-400-i gr7">РУС/ENG</div>
                    </div> */}
                    {/* <span className="line_1"/> */}
                    <div className="row-10-a">
                        <img src={img_pack.youtube_icon} alt="social_icon" />
                        <img src={img_pack.telegram_icon} alt="social_icon" />
                        <img src={img_pack.facebook_icon} alt="social_icon" />
                    </div>
                </div>
                <div className="txtc h-12-400-i gr9">Бесплатная доставка при покупке от 15 000 рублей </div>
                    <div className="row-15-a">
                    <div className="row-10-a">
                        <img src={img_pack.exp_1} alt="exp-img" />
                        <img src={img_pack.exp_2} alt="exp-img" />
                        <img src={img_pack.exp_3} alt="exp-img" />
                    </div>
                    <button className="underline h-12-400-i gr8">Свяжитесь с экспертом</button>
                </div>
            </div>
            }
            <div className="header-main-row header">
                {windowWidth > 1200 ?
                <div className="nav-menu gr6 h-14-600-i">
                    <HoveroutDpd title={'Магазин'} className={'gr6 h-14-600-i'}>
                        <Link to='#!' className="row-5-a">Категория 1</Link>
                        <Link to='#!' className="row-5-a">Категория 2</Link>
                        <Link to='#!' className="row-5-a">Категория 3</Link>
                    </HoveroutDpd>
                    <HoveroutDpd title={'Статьи'} className={'gr6 h-14-600-i'}>
                        <Link to='#!' className="row-5-a">Категория 1</Link>
                        <Link to='#!' className="row-5-a">Категория 2</Link>
                        <Link to='#!' className="row-5-a">Категория 3</Link>
                    </HoveroutDpd>
                    <HoveroutDpd title={'О нас'} className={'gr6 h-14-600-i'}>
                        <Link to='#!' className="row-5-a">Категория 1</Link>
                        <Link to='#!' className="row-5-a">Категория 2</Link>
                        <Link to='#!' className="row-5-a">Категория 3</Link>
                    </HoveroutDpd>
                    <Link to='#!' className="row-5-a">
                        <span>Контакты</span>
                    </Link>
                </div>
                : 
                <button onClick={() => setMenuIsOpen(!menuIsOpen)}>
                    <img src={img_pack.menu_icon} alt="menu_icon" />
                </button>
                }
                <div className="egoda-row-txt">
                    <img src={img_pack.egoda_logo} alt="logo" />
                    <img src={img_pack.egoda_txt} alt="egoda-txt" />
                </div>
                {windowWidth > 1200 ?
                <div className="row-15-a">
                    <Link className="h-24-600-i gr7" to={'#!'}>+7 (999) 999-99-99</Link>
                    <span className="line_2" />
                    <div className="row-15-a">
                        <div className="row-10-a">
                            <img src={img_pack.profile_icon} alt="profile_icon" />
                            <img src={img_pack.cart_icon} alt="cart_icon" />
                        </div>
                        <div className="h-14-400-i gr7">0 ₽</div>
                    </div>
                </div>
                : 
                <div className="row-10-a">
                    <button>
                        <img src={img_pack.search_icon} alt="search_icon" />
                    </button>
                    <button>
                        <img src={img_pack.cart_icon} alt="cart_icon" />
                    </button>
                </div>
                }
            </div>
       </header>
    </div>
    {location.pathname !== '/' &&
    <div className="header-page-nav">
        <div className="container">
            <div className="header-page-nav-content">
                <div className="col">
                    <div className="row-20-a">
                        <button className="gr8 h-12-400-i" onClick={() => navigate(-1)}><img style={{minWidth: 'max-content'}} src={img_pack.back_icon} alt="back_icon" /></button>
                        <div className="h-36-500-c gr8">
                            {location.pathname.toLowerCase() === `${Login_Root.toLowerCase()}` ? 'Авторизация' : 
                            location.pathname.toLowerCase() === `${Registration_Root.toLowerCase()}` ? 'Регистрация' : 
                            location.pathname.toLowerCase() === `${Password_recovery_Root.toLowerCase()}` ? 'Восстановление пароля' : 
                            location.pathname.toLowerCase() === `${Password_new_Root.toLowerCase()}` ? 'Создание нового пароля' : 
                            location.pathname.toLowerCase() === `${Email_verify_Root.toLowerCase()}` ? 'Подтверждение почты' : 
                            location.pathname.toLowerCase() === `${FaqPageRoot.toLowerCase()}` ? 'Вопросы и ответы' : 
                            ''}
                        </div>
                    </div>
                    <div className="row-10-a h-12-400-i">
                        <Link className="gr8 h-12-400-i" to='/'>Главная</Link>
                        {navArr && navArr.map((el) => (
                            <React.Fragment key={el}>
                                <span className="h-12-400-i gr8">/</span>
                                <Link className={`${navArr[navArr.length-1] === el ? 'h-12-700-i' : 'h-12-400-i'}`} to={`/${el}`}>
                                    {`/${el.toLowerCase()}` === `${Login_Root.toLowerCase()}` ? 'Авторизация' : 
                                    `/${el.toLowerCase()}` === `${Registration_Root.toLowerCase()}` ? 'Регистрация' : 
                                    `/${el.toLowerCase()}` === `${Password_recovery_Root.toLowerCase()}` ? 'Восстановление пароля' : 
                                    `/${el.toLowerCase()}` === `${Password_new_Root.toLowerCase()}` ? 'Создание нового пароля' : 
                                    `/${el.toLowerCase()}` === `${Email_verify_Root.toLowerCase()}` ? 'Подтверждение почты' : 
                                    `/${el.toLowerCase()}` === `${FaqPageRoot.toLowerCase()}` ? 'Вопросы и ответы' : 
                                    ''}
                                </Link>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
    }
    </>
    )
}

export default Header;