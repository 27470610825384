import React from "react"

const Pagination = ({totalPages, pagesToShow, currentPage, setCurrentPage}) => {

    return (
        <div>
            {(Array.from({ length: totalPages }, (_, i) => i + 1)).map((el, i) => {
                // Условие для отображения кнопок страниц или "..."
                if (totalPages <= 6) {
                    // Если общее количество страниц меньше или равно 6, отображаем все страницы
                    return (
                        <button key={el} className={`h-14-500-i ${+currentPage === +el ? 'active' : 'c-6'}`} onClick={() => setCurrentPage(el)}>{el}</button>
                    );
                } else {
                    // Рассматриваем разные сценарии для отображения кнопок и "..."
                    // el <= pagesToShow || el > totalPages - pagesToShow || // Первые и последние страницы
                    //     (el >= currentPage - pagesToShow && el <= currentPage + pagesToShow) // Страницы вокруг текущей
                    if (el <= pagesToShow || el > totalPages - pagesToShow || (el >= currentPage - pagesToShow && el <= currentPage + pagesToShow)) { 
                        return (
                            <button key={el} className={` ${+currentPage === +el ? 'active' : ''}`} onClick={() => setCurrentPage(el)}>{el}</button>
                        )
                    } 
                    // (el === pagesToShow + 1 || el === totalPages - pagesToShow - 1) && totalPages > 6)
                    else if ((el === pagesToShow + 1 && currentPage > pagesToShow + 1) || (el === totalPages - pagesToShow - 1 && currentPage < totalPages - pagesToShow - 1)) {
                        // Показываем "..." перед первыми и после последних страниц
                        return <span key={["ellipsis", i, el]}>...</span>;
                    }
                }
                return null; // Возвращаем null для пропуска элементов, которые не должны отображаться
            })}
        </div>
    )
}

export default Pagination;