import React, { useContext, useEffect, useState } from "react"
import img_pack from "../img/img";
import BaseLsidebar from "./components/BaseLsidebar";
import { Context } from "../App";

const UserAgreementPage = () => {

    const {windowWidth} = useContext(Context)

    return (
       <>
        <section className="block-wls"> 
            <div className="container">
                <div className="block-wls-layout">
                    {windowWidth > 1200 &&
                        <BaseLsidebar />
                    }
                    <div className="block-wls-content">

                        <div className="col-20">
                            <div className="h-20-500-c gr8">1. Общие положения</div>
                            <div className="h-14-400-i gr8">1.1. Настоящее Пользовательское соглашение (далее – Соглашение) регулирует отношения между интернет-магазином [Название интернет-магазина] (далее – Магазин) и пользователем (далее – Пользователь) по использованию услуг и сервисов, предоставляемых Магазином.</div>
                            <div className="h-14-400-i gr8">1.2. Использование услуг и сервисов Магазина означает безоговорочное принятие Пользователем всех условий данного Соглашения. В случае несогласия с условиями Соглашения Пользователь обязан прекратить использование услуг и сервисов Магазина.</div>
                        </div>

                        <div className="col-20">
                            <div className="h-20-500-c gr8">2. Регистрация на сайте</div>
                            <div className="h-14-400-i gr8">2.1. Для использования отдельных функций и сервисов Магазина Пользователь должен пройти процедуру регистрации, заполнив регистрационную форму.</div>
                            <div className="h-14-400-i gr8">2.2. Пользователь обязуется предоставить достоверную и актуальную информацию при регистрации. Магазин не несет ответственности за последствия предоставления недостоверной информации.</div>
                            <div className="h-14-400-i gr8">2.3. Пользователь обязуется сохранять конфиденциальность своих учетных данных и не передавать их третьим лицам. В случае утраты учетных данных или их несанкционированного использования Пользователь обязан незамедлительно сообщить об этом Магазину.</div>
                        </div>

                        <div className="col-20">
                            <div className="h-20-500-c gr8">3. Персональные данные</div>
                            <div className="h-14-400-i gr8">3.1. Магазин обрабатывает персональные данные Пользователя в соответствии с Политикой конфиденциальности, которая является неотъемлемой частью данного Соглашения.</div>
                            <div className="h-14-400-i gr8">3.2. Предоставляя свои персональные данные при регистрации, Пользователь дает согласие на их обработку Магазином с целью выполнения обязательств перед Пользователем, а также в целях продвижения товаров и услуг.</div>
                        </div>

                        <div className="col-20">
                            <div className="h-20-500-c gr8">4. Порядок совершения покупок</div>
                            <div className="h-14-400-i gr8">4.1. Пользователь может заказать товары через сайт Магазина, оформляя заказ в соответствии с установленными правилами.</div>
                            <div className="h-14-400-i gr8">4.2. Магазин обязуется предоставить Пользователю точную информацию о товарах, включая их описание, характеристики, цену и условия доставки.</div>
                            <div className="h-14-400-i gr8">4.3. После оформления заказа Пользователь получает подтверждение на указанный при регистрации адрес электронной почты.</div>
                        </div>

                        <div className="col-20">
                            <div className="h-20-500-c gr8">5. Оплата и доставка</div>
                            <div className="h-14-400-i gr8">5.1. Оплата товаров осуществляется способами, указанными на сайте Магазина в разделе "Доставка и оплата".</div>
                            <div className="h-14-400-i gr8">5.2. Условия доставки товаров указаны в разделе "Доставка и оплата" на сайте Магазина.</div>
                        </div>

                        <div className="col-20">
                            <div className="h-20-500-c gr8">6. Возврат и обмен товаров</div>
                            <div className="h-14-400-i gr8">6.1. Пользователь имеет право на возврат или обмен товаров в соответствии с условиями, указанными в разделе "Обмен и возврат" на сайте Магазина.</div>
                        </div>

                        <div className="col-20">
                            <div className="h-20-500-c gr8">7. Права и обязанности сторон</div>
                            <div className="h-14-400-i gr8">7.1. Магазин обязуется:</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Обеспечить доступ к сайту и его сервисам в течение всего времени его работы.</li>
                                <li>Предоставить Пользователю достоверную информацию о товарах и услугах.</li>
                            </ul>
                            <div className="h-14-400-i gr8">7.2. Пользователь обязуется:</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Предоставлять достоверную информацию при регистрации и оформлении заказов.</li>
                                <li>Не использовать сайт и его сервисы для незаконных целей.</li>
                            </ul>
                        </div>

                        <div className="col-20">
                            <div className="h-20-500-c gr8">8. Ответственность</div>
                            <div className="h-14-400-i gr8">8.1. Магазин не несет ответственности за убытки Пользователя, вызванные неправильным использованием товаров, приобретенных в Магазине.</div>
                            <div className="h-14-400-i gr8">8.2. Магазин не несет ответственности за перерывы в работе сайта, вызванные техническими сбоями.</div>
                        </div>

                        <div className="col-20">
                            <div className="h-20-500-c gr8">9. Изменение условий Соглашения</div>
                            <div className="h-14-400-i gr8">9.1. Магазин имеет право вносить изменения в настоящее Соглашение в одностороннем порядке. Изменения вступают в силу с момента их публикации на сайте.</div>
                            <div className="h-14-400-i gr8">9.2. Продолжение использования сайта и его сервисов после внесения изменений в Соглашение означает принятие Пользователем этих изменений.</div>
                        </div>

                        <div className="col-20">
                            <div className="h-20-500-c gr8">10. Прочие условия</div>
                            <div className="h-14-400-i gr8">10.1. Настоящее Соглашение регулируется законодательством Российской Федерации.</div>
                            <div className="h-14-400-i gr8">10.2. В случае возникновения споров, связанных с выполнением данного Соглашения, стороны обязуются решать их путем переговоров. При невозможности достижения соглашения спор подлежит рассмотрению в суде по месту нахождения Магазина.</div>
                            <div className="h-14-400-i gr8">10.3. Все вопросы и предложения Пользователь может направлять по адресу электронной почты: support@example.com.</div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
       </>
    )
}

export default UserAgreementPage;