import React, { useContext, useEffect } from "react"
import { Splide, SplideTrack, SplideSlide} from '@splidejs/react-splide'
import img_pack from "../img/img";
import { Context } from "../App";
import {products, cats, posts} from "../utils/consts";
import ProductExample from "./components/Product_example";
import YandexMap from "./components/YandexMap";

const Main = () => {

    const {windowWidth} = useContext(Context)

    function submit(e) {
        e.preventDefault()
    }

    return (
    <>
        <section className="offer">
            <div className="container">
                <div className="offer-content">
                    <div className="splide-block">
                        <Splide hasTrack={ false } options={{
                            rewind: true,
                            gap: '1rem',
                            perPage: 1,
                            pagination: true,
                        }}>
                            <SplideTrack>
                                <SplideSlide>
                                    <div className="offer-slider-block rg3">
                                        <div>
                                            <div><span className="h-36-500-c gr8">Открой для себя </span><br/><span className="h-36-700-c gr8">секрет красоты</span></div>
                                            <div className="h-20-500-c gr7">Тут будет какой-то описательный текст в 2 или даже 3 строки </div>
                                            <div>
                                                <button className="offer-block-btn c1 white">Перейти на страницу товара</button>
                                            </div>
                                        </div>
                                    </div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="offer-slider-block rg3">
                                        <div>
                                            <div><span className="h-36-500-c gr8">Открой для себя </span><br/><span className="h-36-700-c gr8">секрет красоты</span></div>
                                            <div className="h-20-500-c gr7">Тут будет какой-то описательный текст в 2 или даже 3 строки </div>
                                            <div>
                                                <button className="offer-block-btn c1 white">Перейти на страницу товара</button>
                                            </div>
                                        </div>
                                    </div>
                                </SplideSlide>
                                <SplideSlide>
                                    <div className="offer-slider-block rg3">
                                        <div>
                                            <div><span className="h-36-500-c gr8">Открой для себя </span><br/><span className="h-36-700-c gr8">секрет красоты</span></div>
                                            <div className="h-20-500-c gr7">Тут будет какой-то описательный текст в 2 или даже 3 строки </div>
                                            <div>
                                                <button className="offer-block-btn c1 white">Перейти на страницу товара</button>
                                            </div>
                                        </div>
                                    </div>
                                </SplideSlide>
                            </SplideTrack>
                            <div className="splide__arrows">
                                <button className="splide__arrow splide__arrow--prev"></button>
                                <button className="splide__arrow splide__arrow--next"></button>
                            </div>
                        </Splide>
                    </div>
                    {windowWidth > 1200 &&
                    <div className="offer-content-d-row">
                        <div className="offer-block rg1">
                            <div>
                                <div><span className="h-36-500-c gr8">Открой для себя </span><br/><span className="h-36-700-c gr8">секрет красоты</span></div>
                                <div className="h-20-500-c gr7">Тут будет какой-то описательный текст в 2 или даже 3 строки </div>
                                <div>
                                    <button className="offer-block-btn c1 white">Перейти на страницу товара</button>
                                </div>
                            </div>
                            <img className="offer-block-img" src={img_pack.offer_ex1} alt="offer_ex1" />
                        </div>
                        <div className="offer-block rg2">
                            <div>
                                <div><span className="h-36-500-c gr8">Открой для себя </span><br/><span className="h-36-700-c gr8">секрет красоты</span></div>
                                <div className="h-20-500-c gr7">Тут будет какой-то описательный текст в 2 или даже 3 строки </div>
                                <div>
                                    <button className="offer-block-btn c2 white">Перейти на страницу товара</button>
                                </div>
                            </div>
                            <img className="offer-block-img" src={img_pack.offer_ex2} alt="offer_ex2" />
                        </div>
                    </div>
                    }
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container">
                <div className="block-base-content">
                    <div className="h-36-500-c txtc gr8">Лучшее решение проблем</div>
                    <div className="block2-row h-14-400-i">
                        <div className="col-25-a">
                            <img src={img_pack.block2_1} alt="block2_img" />
                            <span className="txtc">Розацея</span>
                        </div>
                        <div className="col-25-a">
                            <img src={img_pack.block2_2} alt="block2_img" />
                            <span className="txtc">Жирная кожа</span>
                        </div>
                        <div className="col-25-a">
                            <img src={img_pack.block2_3} alt="block2_img" />
                            <span className="txtc">Акне</span>
                        </div>
                        <div className="col-25-a">
                            <img src={img_pack.block2_4} alt="block2_img" />
                            <span className="txtc">Рубцы</span>
                        </div>
                        <div className="col-25-a">
                            <img src={img_pack.block2_5} alt="block2_img" />
                            <span className="txtc">Комедоны</span>
                        </div>
                        <div className="col-25-a">
                            <img src={img_pack.block2_6} alt="block2_img" />
                            <span className="txtc">Сухая кожа</span>
                        </div>
                        <div className="col-25-a">
                            <img src={img_pack.block2_7} alt="block2_img" />
                            <span className="txtc">Морщины</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container">
                <div className="block-base-content">
                    <div className="products-title">
                        <div className="h-36-500-c gr8">Новые поступления</div>
                        <div className="row-20-a">
                            <div className="products-cat-nav">
                                <button className="h-14-600-i gr7">Все</button>
                                <button className="h-14-600-i gr7">Для лица</button>
                                <button className="h-14-600-i gr7">Для волос</button>
                                <button className="h-14-600-i gr7">Для тела</button>
                            </div>
                            {windowWidth >600 &&
                            <div>
                                <button className="viewAll-btn">Посмотреть все</button>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="products-block">
                        {products.map((el,i) => (
                            <ProductExample key={i} res={el}></ProductExample>
                        ))}
                    </div>
                    {windowWidth <= 600 &&
                        <button className="viewAll-btn" style={{maxWidth: '80%', width: '100%', margin: '0 auto'}}>Посмотреть все</button>
                    }
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container">
                <div className="block-base-content">
                    <div className="products-title">
                        <div className="h-36-500-c gr8">Категории товаров</div>
                        {windowWidth >900 &&
                        <div>
                            <button className="viewAll-btn">Посмотреть все</button>
                        </div>
                        }
                    </div>
                    <div className="cat-block">

                        {windowWidth >1200 ?
                        <>
                        <div className="cat-ver cat-base">
                            <div className="cat-ex-content">
                                <div className="h-40-500-c gr8">{cats[0].title}</div>
                                <div className="h-20-500-c gr7">{cats[0].desc}</div>
                                <button className="cat-btn">Посмотреть</button>
                            </div>
                        </div>
                        <div className="col-10-a wh100">
                            <div className="row-10-a wh100">
                                <div className="cat-hor-min cat-base b1">
                                    <div className="cat-ex-content">
                                        <div className="h-40-500-c gr8">{cats[1].title}</div>
                                        <div className="h-20-500-c gr7">{cats[1].desc}</div>
                                        <button className="cat-btn">Посмотреть</button>
                                    </div>
                                </div>
                                <div className="cat-hor-min cat-base b2">
                                    <div className="cat-ex-content">
                                        <div className="h-40-500-c gr8">{cats[2].title}</div>
                                        <div className="h-20-500-c gr7">{cats[2].desc}</div>
                                        <button className="cat-btn">Посмотреть</button>
                                    </div>
                                </div>
                            </div>
                            <div className="cat-hor cat-base">
                                <div className="cat-ex-content">
                                    <div className="h-40-500-c gr8">{cats[3].title}</div>
                                    <div className="h-20-500-c gr7">{cats[3].desc}</div>
                                    <button className="cat-btn">Посмотреть</button>
                                </div>
                            </div>
                        </div>
                        </>
                        :
                        <>
                        <div className="col-10-a wh100">
                            <div className="cat-base cat-hor-min b3">
                                {windowWidth > 600 ?
                                <div className="cat-ex-content">
                                    <div className="h-40-500-c gr8">{cats[0].title}</div>
                                    <div className="h-20-500-c gr7">{cats[0].desc}</div>
                                    <button className="cat-btn h-16-500-c">Посмотреть</button>
                                </div>
                                :
                                <div className="cat-ex-content">
                                    <button className="cat-btn">{cats[0].title}</button>
                                </div>
                                }
                            </div>
                            <div className="cat-base cat-hor-min b2">
                                {windowWidth > 600 ?
                                <div className="cat-ex-content">
                                    <div className="h-40-500-c gr8">{cats[2].title}</div>
                                    <div className="h-20-500-c gr7">{cats[2].desc}</div>
                                    <button className="cat-btn">Посмотреть</button>
                                </div>
                                :
                                <div className="cat-ex-content">
                                    <button className="cat-btn">{cats[2].title}</button>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="col-10-a wh100">
                            <div className="cat-base cat-hor-min b1">
                                {windowWidth > 600 ?
                                <div className="cat-ex-content">
                                    <div className="h-40-500-c gr8">{cats[1].title}</div>
                                    <div className="h-20-500-c gr7">{cats[1].desc}</div>
                                    <button className="cat-btn">Посмотреть</button>
                                </div>
                                :
                                <div className="cat-ex-content">
                                    <button className="cat-btn">{cats[1].title}</button>
                                </div>
                                }
                            </div>
                            <div className="cat-base cat-hor-min b4">
                                {windowWidth > 600 ?
                                <div className="cat-ex-content">
                                    <div className="h-40-500-c gr8">{cats[3].title}</div>
                                    <div className="h-20-500-c gr7">{cats[3].desc}</div>
                                    <button className="cat-btn">Посмотреть</button>
                                </div>
                                :
                                <div className="cat-ex-content">
                                    <button className="cat-btn">{cats[3].title}</button>
                                </div>
                                }
                            </div>
                        </div>
                        </>
                        }   
                    </div>  
                    {windowWidth <= 900 &&
                        <button className="viewAll-btn" style={{maxWidth: '80%', width: '100%', margin: '0 auto'}}>Посмотреть все</button>
                    }
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container">
                <div className="block-base-content">
                    
                    {windowWidth > 900 ?
                    <div className="gift-block">
                        <div className="gift-ex-content">
                            <div className="h-60-700-c gr8">Подарок</div>
                            <div className="h-45-500-c gr8">на любой случай </div>
                            <div>
                                <button className="gift-buy-btn h-14-400-i white">Приобрести</button>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="col-25-a">
                        <div className="h-36-500-c gr8 txtc">Подарок на любой случай</div>
                        <button className="gift-btn">
                            <img src={img_pack.gift_back2} alt="img" />
                        </button>
                    </div>
                    }
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container">
                <div className="block-base-content">
                    <div className="adv-block">
                        <div>
                            <div className="adv-block-title">
                                <div className="h-36-500-c gr8">Рекламный блок бренда</div>
                                <div className="h-16-400-i gr8">Описание для рекламного блока бренда</div>
                            </div>
                            <div className="splide-block" style={
                                windowWidth > 900 ? {width: '100%'} : 
                                windowWidth > 650 ? {width: '600px'} : 
                                windowWidth <= 650 ? {width: '330px'} :
                                {}}>
                                <Splide hasTrack={ false } options={{
                                    rewind: true,
                                    gap: '1rem',
                                    perPage: 3,
                                    pagination: false,
                                    breakpoints: {
                                        900: {
                                          perPage: 2,
                                        }
                                    }
                                }}>
                                    {windowWidth > 1275 &&
                                    <div className="row-20-js" style={{marginBottom: '50px'}}>
                                        <div className="splide__arrows outside_splide__arrows">
                                            <button className="splide__arrow splide__arrow--prev"></button>
                                            <button className="splide__arrow splide__arrow--next"></button>
                                        </div>
                                        <button className="viewAll-btn">Посмотреть все</button>
                                    </div>
                                    }
                                    <SplideTrack>
                                        {products.map((el,i) => (
                                            <SplideSlide key={i}>
                                                <ProductExample res={el}></ProductExample>
                                            </SplideSlide>
                                        ))
                                        }
                                    </SplideTrack>
                                    {windowWidth <=1275 &&
                                    <div className="splide__arrows">
                                        <button className="splide__arrow splide__arrow--prev"></button>
                                        <button className="splide__arrow splide__arrow--next"></button>
                                    </div>
                                    }
                                </Splide>
                            </div>
                        </div>
                        <div className="adv-img">
                            <img src={img_pack.adv_img} alt="adv_img" />
                        </div>
                        {windowWidth <=1275 &&
                        <button className="viewAll-btn" style={{maxWidth: '100%', width: '100%', margin: '0 auto'}}>Посмотреть все</button>
                        }
                    </div>
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container">
                <div className="block-base-content">
                    <div className="products-title">
                        <div className="h-36-500-c gr8">Популярное</div>
                        <div className="row-20-a">
                            <div className="products-cat-nav">
                                <button className="h-14-600-i gr7">Все</button>
                                <button className="h-14-600-i gr7">Для лица</button>
                                <button className="h-14-600-i gr7">Для волос</button>
                                <button className="h-14-600-i gr7">Для тела</button>
                            </div>
                            {windowWidth >600 &&
                            <div>
                                <button className="viewAll-btn">Посмотреть все</button>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="products-block">
                        {products.map((el,i) => (
                            <ProductExample key={i} res={el}></ProductExample>
                        ))}
                    </div>
                    {windowWidth <= 600 &&
                        <button className="viewAll-btn" style={{maxWidth: '80%', width: '100%', margin: '0 auto'}}>Посмотреть все</button>
                    }
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container">
                <div className="block-base-content">
                    <div className="row-20-js" style={ windowWidth <= 900 ? {textAlign: 'center', justifyContent: 'center'} : {}}>
                        <div className="col-10">
                            <div className="h-36-500-c gr8">Популярные бренды</div>
                            <div className="h-16-400-i gr8">Откройте для себя множество товаров от популярных брендов</div>
                        </div>
                        {windowWidth > 900 &&
                        <div>
                            <button className="viewAll-btn">Посмотреть все</button>
                        </div>
                        }
                    </div>
                    <div className="brands-block">
                        <div className="brand-ex">
                            <img src={img_pack.brand_ex} alt="img" />
                        </div>
                        <div className="brand-ex">
                            <img src={img_pack.brand_ex} alt="img" />
                        </div>
                        <div className="brand-ex">
                            <img src={img_pack.brand_ex} alt="img" />
                        </div>
                        <div className="brand-ex">
                            <img src={img_pack.brand_ex} alt="img" />
                        </div>
                    </div>
                    {windowWidth <= 900 &&
                        <button className="viewAll-btn" style={{maxWidth: '80%', width: '100%', margin: '0 auto'}}>Посмотреть все</button>
                    }
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container"> 
                <div className="block-base-content">
                    <div className="products-title">
                        <div className="col-10">
                            <div className="h-36-500-c gr8">Наш блог</div>
                            <div className="h-16-400-i gr8">Секреты поддержания красоты</div>
                        </div>
                        <div className="row-20-a">
                            <div className="products-cat-nav">
                                <button className="h-14-600-i gr7">Все</button>
                                <button className="h-14-600-i gr7">Для лица</button>
                                <button className="h-14-600-i gr7">Для волос</button>
                                <button className="h-14-600-i gr7">Для тела</button>
                            </div>
                            {windowWidth >600 &&
                            <div>
                                <button className="viewAll-btn">Посмотреть все</button>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="blog-block">
                        {posts.map((el) => (
                            <div key={el.id} className="blog-ex">
                                <div className="blog-ex-img"><img src={el.img} alt="img" /></div>
                                <div className="blog-ex-content">
                                    <div className="h-12-400-i gr5 txtc">{el.category}</div>
                                    <div className="h-20-500-c ">{el.title}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container"> 
                <div className="block-base-content">
                    <div className="contacts-block">
                        <div className="Ymap_cotnainer">
                            {/* <YandexMap /> */}
                        </div>
                        <div>
                            <div className="h-30-500-c gr8 txtc">Контактная информация</div>
                            <div className="contacts-row">
                                <div className="contacts-ex">
                                    <div><img src={img_pack.prf_mail} alt="prf_mail" /></div>
                                    <div className="contacts-ex-col">
                                        <div className="h-20-600-i gr9">Почта </div>
                                        <div className="h-16-600-i br">support@egoda.com</div>
                                    </div>
                                </div>  
                                <div className="contacts-ex">
                                    <div><img src={img_pack.prf_location} alt="prf_location" /></div>
                                    <div className="contacts-ex-col">
                                        <div className="h-20-600-i gr9">Адрес </div>
                                        <div className="h-16-600-i br">Адрес, дом, офис</div>
                                    </div>
                                </div>  
                                <div className="contacts-ex">
                                    <div><img src={img_pack.prf_tel} alt="prf_tel" /></div>
                                    <div className="contacts-ex-col">
                                        <div className="h-20-600-i gr9">Телефон </div>
                                        <div className="h-16-600-i br">+7 (999) 999-9999</div>
                                    </div>
                                </div>  
                                <div className="contacts-ex">
                                    <div><img src={img_pack.prf_hours} alt="prf_hours" /></div>
                                    <div className="contacts-ex-col">
                                        <div className="h-20-600-i gr9">Время работы </div>
                                        <div className="h-16-600-i br">пн-пт: 9.00 - 18.00<br/>сб-вс: 11.00-17.00 </div>
                                    </div>
                                </div>  
                            </div>  
                            <div className="row-25-a">
                                <div className="h-14-400-i">Мы в социальных сетях:</div>
                                <div className="row-10-a">
                                    <img src={img_pack.youtube_icon} alt="social_icon" />
                                    <img src={img_pack.telegram_icon} alt="social_icon" />
                                    <img src={img_pack.facebook_icon} alt="social_icon" />
                                </div>
                            </div>
                            <div className="col-20-a">
                                <div className="h-14-400-i">Подписаться на новости, скидки и акции нашего магазина:</div>
                                <form onSubmit={submit} className="contacts-form">
                                    <input type="text" className="base-input h-16-400-i gr5 w1" placeholder="olivia@untitledui.com" />
                                    <button type="submit" className="base-submit h-14-600-i white">Подписаться</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}

export default Main;