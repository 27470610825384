import {React, useRef, useEffect} from "react"
import { useLocation, useNavigate } from "react-router-dom"

const ItemPo = () => {

    const location = useLocation()
    const navigate = useNavigate()

    const prevLocation = location.state?.prevLocation
    const itemPodata = location.state?.itemPodata

    function confirm() {
        navigate(`${prevLocation.pathname}`, {state: {confirmPo: true}})
    }

    return(
        <div className="item_po">
            <span className="item_delete_po_svg"></span>
            <span className="h-18-500-i c-7">{itemPodata.title}</span>
            <span className="h-14-400-i c-6">{itemPodata.desc}</span>
            <span className="item_po_line"></span>
            <div className="item_po_btns h-300-14-r">
                <button className="po_сancel_btn h-16-600-i gr7" onClick={() => navigate(-1)}>Отменить</button>
                <button className="po_delete_btn h-16-600-i white" onClick={confirm}>Удалить</button>
            </div>
        </div>
    )
} 

export default ItemPo