import React, { useContext, useEffect, useState } from "react"
import img_pack from "../img/img";
import BaseLsidebar from "./components/BaseLsidebar";
import { Context } from "../App";

const PaymentAndDeliveryPage = () => {

    const {windowWidth} = useContext(Context)

    return (
       <>
        <section className="block-wls"> 
            <div className="container">
                <div className="block-wls-layout">
                    {windowWidth > 1200 &&
                        <BaseLsidebar />
                    }
                    <div className="block-wls-content">
                        <div className="wi-row">
                            <div className="col-20">
                                <div className="h-28-500-c gr8">Доставка</div>
                                <div className="h-14-400-i gr8">Мы предлагаем несколько вариантов доставки, чтобы вы могли выбрать наиболее удобный для вас способ получения заказанных товаров:</div>
                                <div className="h-20-500-c gr8">Курьерская доставка</div>
                                <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                    <li>По городу: Доставка по городу осуществляется в течение 1-3 рабочих дней. Стоимость доставки - 300 рублей. При заказе на сумму свыше 5000 рублей доставка бесплатна.</li>
                                    <li>Междугородняя: Доставка в другие города занимает 3-7 рабочих дней. Стоимость рассчитывается в зависимости от региона.</li>
                                </ul>
                            </div>
                            <div>
                                <img src={img_pack.delivery_block1} alt="img" />
                            </div>
                        </div>
                        <div className="col-20">
                            <div className="h-20-500-c gr8">Самовывоз</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Вы можете самостоятельно забрать свой заказ из нашего пункта самовывоза по адресу: г. Челябинск *адрес*. Самовывоз доступен в течение 24 часов с момента готовности заказа. Стоимость - бесплатно.</li>
                            </ul>
                            <div className="h-20-500-c gr8">Почтовая доставка</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Отправка заказов через Почту России. Срок доставки составляет 5-14 рабочих дней в зависимости от удаленности региона. Стоимость - по тарифам Почты России.</li>
                            </ul>
                        </div>
                        <div className="col-20">
                            <div className="h-28-500-c gr8">Оплата</div>
                            <div className="h-14-400-i gr8">Мы предлагаем несколько способов оплаты, чтобы вы могли выбрать наиболее удобный:</div>
                            <div className="h-20-500-c gr8">Банковская карта</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Оплата онлайн банковскими картами российских банков, МИР, оплата по QR. Процесс оплаты проходит через защищенный платежный шлюз.</li>
                            </ul>
                            <div className="h-20-500-c gr8">Электронные кошельки</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Мы принимаем оплату через популярные электронные кошельки: Яндекс.Деньги, WebMoney.</li>
                            </ul>
                            <div className="h-20-500-c gr8">Наличный расчет</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Оплата наличными возможна при курьерской доставке и самовывозе. Пожалуйста, подготовьте точную сумму для оплаты курьеру.</li>
                            </ul>
                            <div className="h-20-500-c gr8">Безналичный расчет для юридических лиц</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Мы принимаем оплату по безналичному расчету для юридических лиц. Счет для оплаты будет выставлен после оформления заказа.</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </section>
       </>
    )
}

export default PaymentAndDeliveryPage;