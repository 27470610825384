import React, { useContext, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import img_pack from "../img/img";
import GetMediafiles from "../API/GetMediafiles";
import { useFetching } from "../hooks/UseFetching";
import { AdminMediafilesRedRoot, AdminMediafilesRedRootBase, egoda_url } from "../utils/consts";

const AdminMediafilesPage = () => {

    const [allFiles, setAllFiles] = useState([])
    const [totalFiles, setTotalFiles] = useState('')

    // Добавить файлы
    const [FileUpload, loading, error] = useFetching( async (data) => {
        const res = await GetMediafiles.FileUpload(data)
        console.log('FileUpload complete:' , res.data)
    })
    const [GetAllFiles] = useFetching( async (page, category_slug) => {
        const res = await GetMediafiles.GetAllFiles(page, category_slug)
        console.log('GetAllFiles complete:' , res.data)
        setAllFiles(res.data.data)
        setTotalFiles(res.data.total)
    })

    useEffect(() => {
        GetAllFiles(1,'')
    },[])

    useEffect(() => {
        console.log('allFiles', allFiles)
    },[allFiles])

    useEffect(() => {
        error &&
        console.log(error)
    }, [error])

    function clickInputFile(e) {
        e.currentTarget.querySelector('input[type=file]').click()
    }
    function fileInputChange(e) {
        const filesArray = Array.from(e.target.files);
        const formdata = new FormData();
        filesArray.forEach(el => {
            formdata.append("file", el);
        });
        FileUpload(formdata)
    }

    return (
        <div className="admin_content_base">
            <div className="admin_content_header">
                <div className="row-10-a">
                    <div className="h-18-500-i gr8">Всего</div>
                    <span className="h-12-500-i c-4 ba-or">{totalFiles} шт</span>
                </div>
                <div onClick={clickInputFile} className="admin_upload_btn input_file">
                    <img src={img_pack.upload_icon} alt="upload_icon" />
                    <span className="h-14-600-i">Добавить новый</span>
                    <input type="file" multiple="multiple" onChange={fileInputChange}/>
                </div>
            </div>

            <div className="mediafiles-content">
                {allFiles.map((el) => (
                    <Link to={`${AdminMediafilesRedRootBase}${el.id}`} key={el.id} className="mediafiles-ex"><img src={`${egoda_url}${el.file}`} alt="img" /></Link>
                ))}
            </div>

        </div>
    )
}

export default AdminMediafilesPage;