import youtube_icon from '../img/icons/youtube-icon.svg'
import telegram_icon from '../img/icons/telegram-icon.svg'
import facebook_icon from '../img/icons/facebook-icon.svg'
import toggle_icon_example from '../img/icons/toggle-icon-example.svg'
import egoda_logo from '../img/icons/egoda-logo.svg'
import egoda_txt from '../img/icons/egoda-txt.svg'
import profile_icon from '../img/icons/profile-icon.svg'
import cart_icon from '../img/icons/cart-icon.svg'
import menu_icon from '../img/icons/menu.svg'
import search_icon from '../img/icons/search.svg'
import close_icon from '../img/icons/close-icon.svg'
import dpd_icon from '../img/icons/dpd-icon.svg'
import buy_icon from '../img/icons/buy-btn-icon.svg'
import prf_mail from '../img/icons/prf-mail.png'
import prf_location from '../img/icons/prf-location.png'
import prf_tel from '../img/icons/prf-tel.png'
import prf_hours from '../img/icons/prf-hours.png'
import back_icon from '../img/icons/back-icon.svg'
import notif_icon from '../img/icons/notif-icon.svg'
import upload_icon from '../img/icons/upload-icon.svg'
import trash_icon from '../img/icons/trash-icon.svg'
import save_icon from '../img/icons/save-icon.svg'

import input_help_icon from '../img/icons/input-icon/help-icon.svg'
import input_mail_icon from '../img/icons/input-icon/mail.svg'
import input_unlock_icon from '../img/icons/input-icon/unlock.svg'

import { ReactComponent as admSide_general_icon } from '../img/icons/admSide_icon/general-icon.svg'
import { ReactComponent as admSide_articles_icon } from '../img/icons/admSide_icon/articles-icon.svg'
import { ReactComponent as admSide_shop_icon } from '../img/icons/admSide_icon/shop-icon.svg'
import { ReactComponent as admSide_media_icon } from '../img/icons/admSide_icon/media-icon.svg'
import { ReactComponent as admSide_help_icon } from '../img/icons/admSide_icon/help-icon.svg'
import { ReactComponent as admSide_settings_icon } from '../img/icons/admSide_icon/settings-icon.svg'
import { ReactComponent as admSide_users_icon } from '../img/icons/admSide_icon/users-icon.svg'

import brand_ex from '../img/brand-ex.svg'

import exp_1 from '../img/exp-1.png'
import exp_2 from '../img/exp-2.png'
import exp_3 from '../img/exp-3.png'

import auth_img from '../img/auth-img.png'
import gift_back2 from '../img/gift-back2.png'

import adv_img from '../img/adv-img.jpg'

import offer_ex1 from '../img/offer-ex1.png'
import offer_ex2 from '../img/offer-ex2.png'

import block2_1 from '../img/block2/1.png'
import block2_2 from '../img/block2/2.png'
import block2_3 from '../img/block2/3.png'
import block2_4 from '../img/block2/4.png'
import block2_5 from '../img/block2/5.png'
import block2_6 from '../img/block2/6.png'
import block2_7 from '../img/block2/7.png'

import faq_block1 from '../img/faq-block.png'
import delivery_block1 from '../img/delivery-block.png'
import media_ex from '../img/media-ex.jpg'


const img_pack = {
    youtube_icon,telegram_icon,facebook_icon,toggle_icon_example,
    exp_1,exp_2,exp_3,
    egoda_logo,egoda_txt,
    profile_icon,cart_icon,menu_icon,search_icon,close_icon,dpd_icon,
    offer_ex1,offer_ex2,
    block2_1,block2_2,block2_3,block2_4,block2_5,block2_6,block2_7,
    buy_icon,
    adv_img,
    brand_ex,
    prf_mail,prf_location,prf_tel,prf_hours,
    gift_back2,
    input_help_icon,input_mail_icon,input_unlock_icon,
    auth_img,
    back_icon,
    faq_block1,
    delivery_block1,
    notif_icon,
    upload_icon,media_ex, trash_icon,save_icon,
    admSide_articles_icon,admSide_general_icon,admSide_help_icon,admSide_media_icon,
    admSide_settings_icon,admSide_shop_icon,admSide_users_icon,

}

export default img_pack