import React, { useContext, useEffect } from "react"
import img_pack from "../../img/img";
import { Link } from "react-router-dom";
import { Context } from "../../App";
import CustomDpd from "./Custom_dp_link";

const Footer = () => {

    const {windowWidth} = useContext(Context)


    return (
        <footer>
            <div className="container">
                <div>
                    <div>
                        <div className="egoda-row-txt">
                            <img src={img_pack.egoda_logo} alt="logo" />
                            <img src={img_pack.egoda_txt} alt="egoda-txt" />
                        </div>
                        <div className="h-20-500-c gr7 txtc" style={{maxWidth: '414px'}}>
                            EGODA Shop – интернет-магазин космецевтики самых известных мировых брендов
                        </div>
                    </div>
                    {windowWidth > 650 ?
                    <div>
                        <div className="h-14-400-i">
                            <span className="h-14-600-i gr6">Каталог товаров</span>
                            <Link to={'#!'}>Магазин</Link>
                            <Link to={'#!'}>Бренды</Link>
                            <Link to={'#!'}>Для лица</Link>
                            <Link to={'#!'}>Для тела</Link>
                            <Link to={'#!'}>Для волос</Link>
                            <Link to={'#!'}>Аксессуары</Link>
                        </div>
                        <div className="h-14-400-i">
                            <span className="h-14-600-i gr6">О компании</span>
                            <Link to={'#!'}>О нас</Link>
                            <Link to={'#!'}>Журнал</Link>
                            <Link to={'#!'}>Советы</Link>
                            <Link to={'#!'}>О брендах</Link>
                            <Link to={'#!'}>Личный кабинет</Link>
                        </div>
                        <div className="h-14-400-i">
                            <span className="h-14-600-i gr6">Помощь</span>
                            <Link to={'#!'}>Вопросы и ответы</Link>
                            <Link to={'#!'}>Доставка и оплата</Link>
                            <Link to={'#!'}>Обмен и возврат</Link>
                            <Link to={'#!'}>Программа лояльности</Link>
                            <Link to={'#!'}>Подарочные сертификаты</Link>
                            <Link to={'#!'}>Пользовательское соглашение</Link>
                            <Link to={'#!'}>Публичная оферта</Link>
                            <Link to={'#!'}>Политика конфиденциальности</Link>
                            <Link to={'#!'}>Реквизиты компании</Link>
                        </div>

                    </div>
                    : 
                    <div>
                        <CustomDpd title={'Каталог товаров'}>
                            <Link to={'#!'}>1</Link>
                            <Link to={'#!'}>2</Link>
                            <Link to={'#!'}>3</Link>
                        </CustomDpd>
                        <CustomDpd title={'О компании'}>
                            <Link to={'#!'}>1</Link>
                            <Link to={'#!'}>2</Link>
                            <Link to={'#!'}>3</Link>
                        </CustomDpd>
                        <CustomDpd title={'Помощь'}>
                            <Link to={'#!'}>1</Link>
                            <Link to={'#!'}>2</Link>
                            <Link to={'#!'}>3</Link>
                        </CustomDpd>
                    </div>
                    }
                </div>
                <div>
                    <div className="row-10-a h-16-400-i gr5">
                        <div>© 2024 EGODA Shop — Интернет-магазин космецевтики</div>
                        {windowWidth > 700 &&
                            <>
                                <span className="line_1"/>
                                <Link className="underline" to={'#!'}>Карта сайта</Link>
                            </>
                        }
                    </div>
                    {windowWidth > 1000 &&
                    <div className="row-10-a h-12-500-i gr5">
                        <Link className="underline" to={'#!'}>Ссылка</Link>
                        <span className="line_1"/>
                        <Link className="underline" to={'#!'}>Ссылка</Link>
                        <span className="line_1"/>
                        <Link className="underline" to={'#!'}>Ссылка</Link>
                    </div>
                    }
                </div>
            </div>
        </footer>
    )
}

export default Footer;